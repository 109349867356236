// Globals
$body-font-family: 'Poppins', sans-serif !default;
$border-radius-root: 3px;

// Custom variables
$subtitle-color: #a1aab2;

$info: #1e88e5;
$gray-500: #a1aab2;

$light-primary: #e6ecfc;
$light-warning: #fff8ec;
$light-success: #e8fdf8;
$light-danger: #f9e7eb;

$box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1) !important;

$primary: #1e88e5;
$info: #1e88e5;
$success: #21c1d6;
$accent: #fc4b6c;
$default: #563dea;

$media-breakpoint-up: (
  'xs': 0,
  'sm': 476px,
  'md': 668px,
  'lg': 1000px,
  'xl': 1300px,
);
